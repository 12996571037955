import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../components/button";
import { ReactComponent as PhoneIcon } from "../../static/svg/phone.svg";
import { ReactComponent as PayRefIcon } from "../../static/svg/payRef.svg";
import { ReactComponent as AccountIcon } from "../../static/svg/account2.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  }
}));
export default function ConsultationContent({ title, url, img2 }) {
  const classes = useStyles();
  return (
    <Grid item container className={classes.main}>
      <Grid item xs className={classes.mainItem}>
        <PhoneIcon height="100px" />
        {/* <ButtonComponent fullWidth title="Войти" /> */}
        <Typography align="left" className={classes.mainDescription}>
          Коллекторское агенство "Возврат" осуществляет профессиональную
          юридическую консультацию, связанную с взысканием долгов, защитой от
          угроз, ведением дел в суде, исполнительным производством, а так же
          нестандартных ситуаций любой сложности.
        </Typography>
        <Typography align="left" className={classes.mainDescription}>
          Наши специалисты подскажут, какие шаги стоит предпринять для погашения
          обязательства на комфортных условиях, подберут акции и составят
          удобный график.
        </Typography>
        <Typography align="left" className={classes.mainDescription}>
          Без сомнения, доверительные отношения - это залог успешного разрешения
          финансовых проблем. В любых ситуациях наши специалисты действуют
          профессионально и корректно, соблюдают профессиональный этический
          кодекс.
        </Typography>
      </Grid>
    </Grid>
  );
}
