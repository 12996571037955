import React, { useState } from "react";
import BlockContent from "../components/blockContent";
import ModalInvestInput from "../components/investFrom";
import StockItem from "../components/stockItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  childrenBlock: {
    flexWrap: "wrap"
  },
  title: {
    fontSize: "5rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3rem"
    }
  }
}));

export default function Stock() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ModalInvestInput
        open={open}
        title="Отправить заявку"
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        dataOptions={null}
      />
      <BlockContent
        bigFont
        classNameChildren={classes.childrenBlock}
        title="Акции прощения"
        description="Специальные условия и скидки предусмотрены для всех действующих и будущих клиентов. Примите участие в одной из акций или просто позвоните нам
    для уточнения индивидуальных условий погашения задолженности. Мы будем
    рады пойти навстречу для разрешения Вашей финансовой ситуации."
      >
        <StockItem
          img="spriteSale.jpg"
          title="С Днем Рождения"
          handleClickOpen={handleClickOpen}
          text="Скидка для именинников, спишем до 50% от общей суммы задолженности"
        />
      </BlockContent>
    </>
  );
}
