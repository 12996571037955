import React from "react";
import BorderForComponent from "../components/borderForComponent";
import BlockContent from "../components/blockContent";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "20px",
    borderRadius: "5px",
    background: "#484d62",
    margin: "50px auto",
    "&:hover": {
      // margin: "1px",
    },
    [theme.breakpoints.down("xs")]: {}
  },
  titleMain: {
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    paddingBottom: "30px",
    color: "#F2C807"
  },
  itemContent: {
    margin: "20px 0px 20px 0px",
    padding: "15px",
    textAlign: "left",
    // background:
    //   "linear-gradient(90deg, rgba(251,211,26,1) 0%, rgba(242,200,7,1) 100%)",
    background: "#f3f4f4",
    boxShadow: "1px 2px 7px 0px #552a043b",
    '-webkit-box-shadow': "1px 2px 7px 0px #552a043b",
    borderRadius: "7px"
    // border: "2px solid #ffffffc2"
  }
}));
export default function AdvantagesForClients({title, url, img2}) {
  const classes = useStyles();

  return (
    <Grid item className={classes.main}>
      <p className={classes.titleMain}>Наши преимущества:</p>
      <Typography className={classes.itemContent}>
        Индивидуальный график погашения задолженностей с учётом Ваших жизненных
        обстоятельств и финансовых возможностей
      </Typography>
      <Typography className={classes.itemContent}>
        Выгодные дисконтные программы, позволяющие списать до 50% обязательств
      </Typography>
      <Typography className={classes.itemContent}>
        Удобный и интуитивно понятный онлайн-сервис для решения всех
        сопутствующих вопросов
      </Typography>
      <Typography className={classes.itemContent}>
        100% защищенность и конфиденциальность Вашей информации
      </Typography>
      <Typography className={classes.itemContent}>
        Комфортное избавление от обязательств через открытый диалог с нашими
        внимательными и профессиональными сотрудниками
      </Typography>
    </Grid>
  );
}
