import DebtContent from "./debt";
import PayContent from "./pay";
import ReferenceContent from "./reference";
import ConsultationContent from "./consultation";
import LegalServicesContent from "./legalServices";
import PreTrialContent from "./preTrial";
import StockContent from "./stock";

export const contentItemClient = {
  debt: <DebtContent />,
  pay: <PayContent />,
  reference: <ReferenceContent />,
  consultation: <ConsultationContent />,
  legalServices: <LegalServicesContent />,
  preTrial: <PreTrialContent />,
  stock: <StockContent />
};
