import React, {useState} from "react";
import BorderForComponent from "../../components/borderForComponent";
import ButtonComponent from "../../components/button";
import clsx from "clsx";
import InputMask from "react-input-mask";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "50px 0px 50px 0px",
        boxSizing: "border-box",
    },
    root: {
        boxShadow: '0px 4px 40px 0px #e3e3e3',
        '-webkit-box-shadow': '0px 4px 40px 0px #e3e3e3',
        margin: "40px 0px 40px 0px",
        padding: "50px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px"
        }
    },
    title: {
        fontSize: "3rem",
        paddingBottom: "20px",
        fontFamily: 'Segoe UI',
        color: '#484D62',
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    inputItem: {
        padding: "10px 20px 10px 20px"
    },
    description__formField: {
        textAlign: 'left',
        padding: '10px 14px',
        color: 'rgba(50,50,50,0.7)'
    }
}));

export default function StepPaySecond({data, handleChange}) {

    const classes = useStyles();
    const [sent, setSent] = useState(false);
    //const [data, setData] = useState({
    //    phone: "",
    //    email: "",
    //    amount: "",
    //    birthday: ""
    //});

    //const handleChange = (e) => {
    //    setData({...data, [e.target.name]: e.target.value});
    //};

    const onSubmit = (e) => {
        e.preventDefault();
    }
    const {phone, email, amount, birthday} = data;
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    container
                    alignContent="space-between"
                // className={classes.padding}
                >
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <InputMask
                            mask="+7 (999) 999-99-99"
                            value={phone}
                            onChange={handleChange}
                        >
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="component-outlined">Введите номер телефона *</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    type="tel"
                                    name="phone"
                                    value={phone}
                                    onChange={handleChange}
                                    label="Введите номер телефона *"

                                    required
                                />
                                <span className={classes.description__formField}>На этот номер поступит SMS с кодом подтверждения</span>
                            </FormControl>
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="component-outlined">Введите сумму к погашению *</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                type="number"
                                name="amount"
                                value={amount}
                                onChange={handleChange}
                                label="Введите сумму к погашению *"
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {/*<Grid
                    item
                    xs={12}
                    container
                    className={classes.styleBlockButton}
                    justify="flex-end"
                >
                    <ButtonComponent type="submit" title="Отправить" size="medium" />
                </Grid>*/}
            </Grid>
        </form>
    );
}
