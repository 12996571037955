import React, { useState } from "react";
import ModalDataInput from "../../../components/cardImgText";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../../components/button";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  }
}));
export default function ReferenceContentItem({
  title,
  icon,
  decription,
  buttonTitle,
  disableButton
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ModalDataInput
        open={open}
        title={title}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
      <Grid item xs className={classes.mainItem}>
        {icon}
        <Typography className={classes.mainTitle}>{title}</Typography>
        <ButtonComponent
          fullWidth
          onClick={handleClickOpen}
          title={buttonTitle}
          disabled={disableButton ? true : false}

        />
        <Typography align="left" className={classes.mainDescription}>
          {decription}
        </Typography>
      </Grid>
    </>
  );
}
