import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../components/button";
import {ReactComponent as AccountIcon} from "../../static/svg/account2.svg";
import {ReactComponent as CallCentreIcon} from "../../static/svg/call-centre.svg";
import {ReactComponent as EmailBorder} from "../../static/svg/emailBorder.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  },
  linkButton: {
    width: "100%",
    display: "block",
    textAlign: "center"
  }
}));

export default function StockContent({title, url, img2}) {
  const classes = useStyles();
  return (
    <Grid item container className={classes.main}>
      {/* Вы можете узнать несколькими способами. */}
      <Grid item xs className={classes.mainItem}>
        <AccountIcon height="200px" />
        <Typography className={classes.mainTitle}>
          В Личном кабинете клиента
        </Typography>
        <ButtonComponent disabled fullWidth title="Войти" />
        <Typography align="left" className={classes.mainDescription}>
          Всю необходимую информацию быстро, комфортно и без ожидания вы можете
          узнать в личном кабинете.
        </Typography>
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <CallCentreIcon height="200px" />
        <Typography className={classes.mainTitle}>В контакт-центре</Typography>
        <ButtonComponent
          fullWidth
          title={<a href="tel:79638535184" className={classes.linkButton}>Позвонить</a>}
        />
        <Typography align="left" className={classes.mainDescription}>
          Позвоните в наше Агентство и уточните, какие акции по прощению долга
          распространяются на Вашу задолженность. Специалист предложит Вам одну
          или несколько акций!
        </Typography>
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <EmailBorder height="200px" />
        <Typography className={classes.mainTitle}>
          Написать на электронную почту
        </Typography>
        <ButtonComponent
          fullWidth
          title={<a href="mailto:info@kavozvrat.ru" className={classes.linkButton}>Написать</a>}
        />
        <Typography align="left" className={classes.mainDescription}>
          Если первые два варианта Вам не понравились, можете написать нам на
          электронную почту, мы также ответим на все ваши вопросы.
        </Typography>
      </Grid>
    </Grid>
  );
}
