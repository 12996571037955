import React from "react";
import BorderForComponent from "./borderForComponent";
import BlockContent from "./blockContent";
import ClientServicesItem from "./clientServicesItem";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as CalenderIcon } from "../static/svg/calenderIcon.svg";
import { ReactComponent as PayIcon } from "../static/svg/payIcon.svg";
import { ReactComponent as SaleIcon } from "../static/svg/saleIcon.svg";
import { ReactComponent as SearchIcon } from "../static/svg/searchIcon.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  content__block: {
    paddingBottom: "40px",
  },
}));

export default function Work() {
  const classes = useStyles();
  return (
    <BlockContent
      title='4 шага к "свободе от долгов"'
      className={classes.content}
    >
      <Grid container wrap="nowrap" className={classes.content__block}>
        <ClientServicesItem text="Проанализируем Вашу финансовую ситуацию" svg={<SearchIcon width="100%" />} />
        <ClientServicesItem text="Подбираем для вас акции и предлагаем скидки" svg={<SaleIcon width="100%" />} />
        <ClientServicesItem text="Составляем комфортный график погашения" svg={<CalenderIcon width="100%" />} />
        <ClientServicesItem text="Оплачиваете удобным для Вас способом" svg={<PayIcon width="100%" />} />
      </Grid>
    </BlockContent>
  );
}
