import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1020px',
    },
    main: {
        padding: '40px',
        //maxWidth: '1020px',
        boxSizing: 'border-box',
        margin: '40px auto',
        boxShadow: '0px 4px 40px 0px #e3e3e3',
        '-webkit-box-shadow': '0px 4px 40px 0px #e3e3e3',
        //display: 'grid',
        //gridTemplateColumns: '1fr 3fr',
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("xs")]: {
            //padding: '0px 40px 40px 40px',
        }
    },
    instructionImg_main: {

    },
    instructionImgLeft: {
        gridArea: 'imgLeft',
    },
    instructionImgRight: {
        gridArea: 'imgRight',
    },
    instructionImg: {
        width: '70%',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        }
    },
    detailsContent: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        //width: '50%',
        //padding: '0px 0px 0px 20%',
        textAlign: 'left',
        gridGap: '15px',
        alignItems: 'center',
        margin: '40px 20px',
        [theme.breakpoints.down("xs")]: {
            margin: '40px 0px 20px 0px',
            padding: '0px 0px 20px 0px',
        }
    },
    detailsLeft: {
        fontSize: '1.1rem',
        fontFamily: 'Segoe UI, sans-serif',
        //fontWeight: 'bold',
        color: '#484D62',
    },
    detailsRight: {
        //background: '#f3f4f4',
        //padding: '10px',
        fontSize: '1.4rem',
        color: '#484D62',
        fontFamily: 'Segoe UI, sans-serif',
        fontWeight: '600',
        borderBottom: '1px solid #484d62',
    },
    qrcodeContent: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        [theme.breakpoints.down("xs")]: {
            margin: '0px auto',
        }
    },
    qrcode: {
        width: '200px',
        height: '100%',
    },
    qrcode__download: {
        margin: '0px 10px',
        background: ' #f2c807',
        padding: '7px',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: "#f2d658"
        },
    },
    qrcode__instruction: {
        width: '200px',
        color: '#484d62',
        margin: '20px 0px 0px 0px',
        fontSize: '0.8rem',
    }
}));

const PayBankDetails = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <div className={classes.main}>
                    <div className={classes.qrcodeContent}>
                        <img className={classes.qrcode} src='/images/QR-code_pay.png' alt='QR code pay' />
                        <a className={classes.qrcode__download} href="/images/QR-code_pay.png" download="">Скачать </a>
                        <div className={classes.qrcode__instruction}> Инструкция: зайти в мобильный банк, раздел Платежи / Оплата по QR / Отсканировать QR code</div>
                    </div>
                    <div className={classes.detailsContent}>
                        <div className={classes.detailsLeft}>Счет получателя:</div>
                        <div className={classes.detailsRight}>40702810516090000035</div>
                        <div className={classes.detailsLeft}>Получатель:</div>
                        <div className={classes.detailsRight}>ООО Коллекторское агентство "Возврат"</div>
                        <div className={classes.detailsLeft}>ИНН получателя:</div>
                        <div className={classes.detailsRight}>6658408512 </div>
                        <div className={classes.detailsLeft}>КПП получателя:</div>
                        <div className={classes.detailsRight}>667901001</div>
                        <div className={classes.detailsLeft}>Банк получателя:</div>
                        <div className={classes.detailsRight}>Уральский Банк Сбербанка РФ г. Екатеринбург</div>
                        <div className={classes.detailsLeft}>БИК</div>
                        <div className={classes.detailsRight}>046577674</div>
                        <div className={classes.detailsLeft}>Кор/счет банка</div>
                        <div className={classes.detailsRight}>30101810500000000674</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayBankDetails;
