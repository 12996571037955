import React from "react";
import { Link } from "react-router-dom";

export default function PageConstruction() {
    return (
        <>
            <div style={{ margin: "50px", fontSize: "2rem" }}>Раздел в разработке. </div>
            <div style={{ margin: "50px", fontSize: "1.5rem" }}><Link to="/contact">Перейти в контакты</Link></div>
        </>
    )
}