import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1020px',
    },
    main: {
        padding: '0px 40px',
        textAlign: "left"
    },
    mainTitle: {
        padding: "10px",
        background: "#F3F4F4",
        color: "#484d62",
        margin: "20px 0px 10px 0px",
        textAlign: "center"
    },
    mainItem: {
        margin: "10px"
    },
    mainDescription: {
        margin: "20px auto"
    },
    list: {
        padding: "20px"
    },
    titleBlock: {
        color: "#484D62",
        fontSize: "2rem",
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: "bold",
        padding: "0px 0px 30px 20px"
    },
    title: {
        fontSize: "5rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    content: {
        backgroundColor: "#F3F4F4",
        borderTop: "1px solid #D0D7DD"
    },
    blockImg: {
        border: "1px solid #f3f4f4",
        textAlign: "center",
        borderRadius: "7px",
        background: "#f3f4f4",
        padding: "20px",
        margin: "20px"
    },
    linkButton: {
        width: "100%",
        display: "block",
        textAlign: "center"
    },
    selected: {
        borderBottom: '1px solid black'
    },
    linkItem: {
        padding: '10px',
    },
    main__content_p: {
        margin: '10px 0px'
    }
}));

export default function Policy() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container className={classes.main}>
                <div className={classes.main__content_p}>Политика конфиденциальности
                    Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте kavozvrat.ru, &#40;далее – Сайт&#41;, путем заполнения полей онлайн-оплаты
                </div>


            </Grid>
        </div>
    );
}
