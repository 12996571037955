export const dataInstructionSber = [
    {
        text: 'Зайти в приложение СберБанк, перейти на вкладку "Платежи"',
        imgLeft: 'step-1-1.jpg',
        imgRight: 'step-1-2.jpg',
    },
    {
        text: 'Перейти на поле поиска, ввести "КА Возврат"',
        imgLeft: 'step-2-1.jpg',
        imgRight: 'step-2-2.jpg',
    },
    {
        text: 'Нажать на поиск, ждем загрузки Организаций',
        imgLeft: 'step-3-1.jpg',
        imgRight: 'step-3-2.jpg',
    },
    {
        text: 'Нажать на "Показать всё" и пролистать до "КА ВОЗВРАТ"',
        imgLeft: 'step-4-1.jpg',
        imgRight: 'step-4-2.jpg',
    },
    {
        text: 'Ввести: ФИО, назначение платежа, сумма платежа',
        imgLeft: 'step-5-1.jpg',
        imgRight: 'step-5-2.jpg',
    }
]
