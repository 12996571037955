export const phone_list = [
    {
        phone: '8007002068',
        name: 'Общий'
    },
    {
        phone: '9617767489',
        name: 'Анатолий'
    },
    {
        phone: '9617768159',
        name: 'Юлия'
    },
    {
        phone: '9617768669',
        name: 'Алексей'
    },
    {
        phone: '9617769105',
        name: 'Вячеслав'
    },
    {
        phone: '9638535184',
        name: 'Общий'
    },
    {
        phone: '9655201524',
        name: 'Ольга'
    },
    {
        phone: '9678599488',
        name: 'Светлана'
    },
    {
        phone: '9655201525',
        name: 'Дмитрий'
    },
    {
        phone: '9655201527',
        name: 'Марина'
    },
    {
        phone: '9655201529',
        name: 'Федор'
    },
    {
        phone: '9655201532',
        name: 'Алексей'
    },
    {
        phone: '9655201533',
        name: 'Рустам'
    },
    {
        phone: '9655201534',
        name: '-'
    },
    {
        phone: '9655201540',
        name: 'Игорь'
    },
    {
        phone: '9655201545',
        name: 'Вадим'
    },
    {
        phone: '9655201560',
        name: 'Алла'
    },
    {
        phone: '9655201561',
        name: '-'
    },
    {
        phone: '9655201562',
        name: 'Альберт'
    },
    {
        phone: '9655201564',
        name: 'Михаил'
    },
    {
        phone: '9655201566',
        name: 'Анастасия'
    },
]