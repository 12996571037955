import React, {useState, useEffect} from "react";
import ButtonComponent from "./button";

import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 4px 40px 0px #e3e3e3",
    '-webkit-box-shadow': "0px 4px 40px 0px #e3e3e3",
    margin: "40px 0px 40px 0px",
    padding: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px"
    }
  },
  slider: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: "8%"
    }
  },
  result: {
    // paddingTop: "50px",
    textAlign: "left",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "10%",
      flexDirection: "row",
      borderLeft: "4px solid #484d62"
    }
  },
  slider_item: {
    background:
      "-webkit-linear-gradient(left, rgb(0, 155, 69) 0%, rgb(0, 155, 69) 0%, rgb(216, 216, 216) 0%, rgb(216, 216, 216) 100%)"
  },
  radio: {
    // width: "250px"
    height: "40px"
  },
  inputValue: {
    fontSize: "1.8rem",
    margin: "0px 0px 20px 0px",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    }
  },
  input: {
    padding: "0px 40px 40px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 50px 0px"
    }
  },
  inputRadio: {
    alignItems: "flex-end",
    textAlign: "left"
  },
  radioItem: {
    position: "absolute",
    zIndex: "-1",
    width: "0",
    height: "0"
  },
  checked: {
    border: "1px solid #484d62",
    // padding: "20px",
    borderRadius: "7px"
  },
  payout: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {fontSize: "0.8rem"},
  value: {
    paddingTop: "7px",
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: "#484d62"
  },
  result_item: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "space-between"
    }
  },
  investment: {
    fontSize: "0.8rem"
  }
  // slider: {
  //   WebkitAppearance: "none",
  //   appearance: "none",
  //   width: "100%",
  //   height: "25px",
  //   background: "#d3d3d3",
  //   outline: "none",
  //   opacity: "0.7",
  //   WebkitTransition: ".2s",
  //   transition: "opacity .2s",
  //   "&:hover": {
  //     opacity: 1
  //   },
  //   "&::-webkit-slider-thumb": {
  //     WebkitAppearance: "none",
  //     appearance: "none",
  //     width: "25px",
  //     height: "25px",
  //     background: "#4CAF50",
  //     cursor: "pointer"
  //   },
  //   "&::-moz-range-thumb": {
  //     width: "25px",
  //     height: "25px",
  //     background: "#4CAF50",
  //     cursor: "pointer"
  //   }
  // }
}));

export default function InvestmentsForm({handleClickOpen, handleChange, setOptions, options}) {
  const classes = useStyles();
  // const [options, setOptions] = useState({
  //   month: 12,
  //   sum: 500000,
  //   interest: "monthly",
  //   interestRate: 16,
  //   monthlyIncome: 0,
  //   totalIncome: 0
  // });
  // const handleChange = (e) => {
  //   setOptions({ ...options, [e.target.name]: e.target.value });
  // };
  useEffect(() => {
    const allowance = options.interest === "finish" && 1;
    if (Number(options.month) >= 36) {
      setOptions((o) => {
        return {...o, interestRate: Number(20 + allowance)};
      });
    } else if (Number(options.month) >= 18) {
      setOptions((o) => {
        return {...o, interestRate: Number(19 + allowance)};
      });
    } else {
      setOptions((o) => {
        return {...o, interestRate: Number(18 + allowance)};
      });
    }
  }, [options.month, options.interest]);

  useEffect(() => {
    setOptions((o) => {
      const result = Math.floor(
        ((options.interestRate / 12) * options.sum) / 100
      );
      return {
        ...o,
        monthlyIncome: result,
        totalIncome: result * options.month
      };
    });
  }, [options.sum, options.interestRate, options.month, options.monthlyIncome]);
  return (
    <Grid container className={classes.root}>
      <Grid item sm={8} container className={classes.slider}>
        <Grid item sm={12} xs={12} className={classes.input}>
          <Grid
            item
            container
            justify="space-between"
            align="center"
            className={classes.inputValue}
          >
            <Grid item>{options.sum} &#8381;</Grid>
            <Grid item className={classes.investment}>
              сумма инвестиций
            </Grid>
          </Grid>
          <input
            type="range"
            id="startAmountInvestment"
            step="50000"
            min="1000000"
            max="5000000"
            name="sum"
            onChange={handleChange}
            value={options.sum}
            className="slider"
          />
        </Grid>
        <Grid item sm={12} xs={12} className={classes.input}>
          <Grid
            item
            container
            justify="space-between"
            className={classes.inputValue}
          >
            <Grid item>{options.month} мес.</Grid>
            <Grid item className={classes.investment}>
              срок инвестиций
            </Grid>
          </Grid>
          <input
            type="range"
            id="startAmountInvestment"
            step="3"
            min="12"
            max="36"
            name="month"
            onChange={handleChange}
            value={options.month}
            className="slider"
          />
        </Grid>
        <Grid item sm={12} xs={12} container className={classes.inputRadio}>
          <Grid item xs>
            <div className={classes.radio}>
              <input
                type="radio"
                name="interest"
                id="monthly"
                value="monthly"
                checked={options.interest === "monthly"}
                onChange={handleChange}
                className={classes.radioItem}
              />
              <label
                for="monthly"
                className={clsx(
                  options.interest === "monthly" && classes.checked,
                  classes.payout
                )}
              >
                Ежемесячно
              </label>
            </div>
          </Grid>
          <Grid item xs>
            <div className={classes.radio}>
              <input
                type="radio"
                name="interest"
                id="finish"
                value="finish"
                checked={options.interest === "finish"}
                onChange={handleChange}
                className={classes.radioItem}
              />
              <label
                for="finish"
                className={clsx(
                  options.interest === "finish" && classes.checked,
                  classes.payout
                )}
              >
                В конце срока
              </label>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        container
        alignContent="space-between"
        className={classes.result}
      >
        <Grid
          item
          container
          direction="column"
          sm={12}
          xs
          className={classes.result_item}
        >
          <span className={classes.title}>Процентная ставка</span>
          <span className={classes.value}>{options.interestRate}%</span>
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={12}
          xs
          className={classes.result_item}
        >
          <span className={classes.title}>Ежемесячный доход</span>
          <span className={classes.value}>{options.monthlyIncome} &#8381;</span>
        </Grid>
        <Grid
          item
          container
          direction="column"
          sm={12}
          xs
          className={classes.result_item}
        >
          <span className={classes.title}>Итоговый доход *</span>
          <span className={classes.value}>{options.totalIncome} &#8381;</span>
        </Grid>
        <br />
        <Grid item>
          <ButtonComponent title="Инвестировать" onClick={handleClickOpen} />
        </Grid>
      </Grid>
    </Grid>
  );
}
