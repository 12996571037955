import React from "react";
import Slider from "./slider";
import Work from "./work";
import Services from "./services";
import News from "./news";

export default function Wrapper() {
  return (
    <>
      <Slider />
      <Work />
      <Services />
      {/* <News /> */}
    </>
  );
}
