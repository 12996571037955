import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Link } from "react-router-dom";
import { ReactComponent as AccountIcon } from "../static/svg/menu/accountIcon.svg";
import { ReactComponent as HomeIcon } from "../static/svg/menu/homeIcon.svg";
import { ReactComponent as PayIcon } from "../static/svg/menu/payIcon.svg";
import { ReactComponent as PhoneIcon } from "../static/svg/menu/phoneIcon.svg";
import { ReactComponent as SaleIcon } from "../static/svg/menu/saleIcon.svg";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: "0",
    zIndex: "1111",
    background: "#484D62",
    borderTop: "1px solid #363636"
  },
  navigation: {
    minWidth: "72px",
    color: "#fff"
  },
  selected: {
    color: "#F2C807!important"
  }
});

export default function BottomNavigationMobile() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        className={classes.navigation}
        classes={{
          selected: classes.selected
        }}
        label="Главная"
        icon={
          <Link to="/">
            <HomeIcon />
          </Link>
        }
      />

      <BottomNavigationAction
        className={classes.navigation}
        classes={{
          selected: classes.selected
        }}
        label="Акции"
        icon={
          <Link to="/stock">
            <SaleIcon />
          </Link>
        }
      />

      <BottomNavigationAction
        className={classes.navigation}
        classes={{
          selected: classes.selected
        }}
        label="Оплата"
        icon={
          <Link to="/client/pay">
            <PayIcon />
          </Link>
        }
      />

      <BottomNavigationAction
        className={classes.navigation}
        classes={{
          selected: classes.selected
        }}
        label="Контакты"
        icon={
          <Link to="/contact">
            <PhoneIcon />
          </Link>
        }
      />

      <BottomNavigationAction
        className={classes.navigation}
        classes={{
          selected: classes.selected
        }}
        label="Услуги"
        icon={
          <Link to="/client">
            <AccountIcon />
          </Link>
        }
      />
    </BottomNavigation>
  );
}
