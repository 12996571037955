import React from "react";
import {NavLink, Switch, Route} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PersonalInformation from './personalInformation';
import Privacy from './privacy';
import Policy from './policy';
import PolicyPdf from '../../static/pdf/policy_1v.pdf';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "20px 0px 20px 0px",
        boxSizing: "border-box",
    },
    root: {
        boxShadow: "0px 4px 40px 0px #e3e3e3",
        '-webkit-box-shadow': "0px 4px 40px 0px #e3e3e3",
        margin: "40px 0px 40px 0px",
        padding: "50px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px"
        }
    },
    title: {
        fontSize: "3rem",
        paddingBottom: "20px",
        fontFamily: 'Segoe UI',
        color: '#484D62',
        [theme.breakpoints.down("xs")]: {
            fontSize: "2.5rem"
        }
    },
    mainTitle: {
        padding: "10px",
        background: "#F3F4F4",
        color: "#484d62",
        margin: "20px 0px 10px 0px",
        textAlign: "center"
    },
    mainItem: {
        margin: "10px"
    },
    mainDescription: {
        margin: "20px auto"
    },
    list: {
        padding: "20px"
    },
    titleBlock: {
        color: "#484D62",
        fontSize: "2rem",
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: "bold",
        padding: "0px 0px 30px 20px"
    },
    title: {
        fontSize: "4rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    content: {
        backgroundColor: "#F3F4F4",
        borderTop: "1px solid #D0D7DD"
    },
    blockImg: {
        border: "1px solid #f3f4f4",
        textAlign: "center",
        borderRadius: "7px",
        background: "#f3f4f4",
        padding: "20px",
        margin: "20px"
    },
    linkButton: {
        width: "100%",
        display: "block",
        textAlign: "center"
    },
    selected: {
        background: '#F2C807',
        borderBottom: 'none!important'
    },
    linkItem: {
        padding: '10px',
    },
    linkItem__content: {
        padding: '5px',
        borderBottom: '1px dashed black',
    }
}));

export default function OfficialInfo() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.main} >
                <h2 className={classes.title}>Правовая информация</h2>
            </div>
            <Grid container justify="center">
                <Grid item className={classes.linkItem}>
                    <NavLink exact to='/official-info' activeClassName={classes.selected} className={classes.linkItem__content}>Cогласие на обработку персональных данных</NavLink>
                </Grid>
                <Grid item className={classes.linkItem}>
                    <NavLink to='/official-info/privacy' activeClassName={classes.selected} className={classes.linkItem__content}>Политика конфиденциальности</NavLink>
                </Grid>
                <Grid item className={classes.linkItem}>
                    <NavLink to={PolicyPdf} activeClassName={classes.selected} className={classes.linkItem__content} target="_blank">Политика в области обработки и защиты персональных данных</NavLink>
                </Grid>
            </Grid>
            <Grid container className={classes.main}>
                <Switch>
                    <Route exact path="/official-info">
                        <PersonalInformation />
                    </Route>
                    <Route path="/official-info/privacy">
                        <Privacy />
                    </Route>
                    <Route path="/official-info/policy">
                        <Policy />
                    </Route>
                </Switch>
            </Grid>
        </>
    );
}
