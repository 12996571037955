import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";
import ButtonComponent from "../../components/button";
import {ReactComponent as PayManIcon} from "../../static/svg/payMan.svg";
import {ReactComponent as PayRefIcon} from "../../static/svg/payRef.svg";
import {ReactComponent as AccountIcon} from "../../static/svg/account2.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  }
}));
export default function PayContent({title, url, img2}) {
  const classes = useStyles();
  const history = useHistory();
  const redirectPage = () => {
    history.push("/pay");
  }
  return (
    <Grid item container className={classes.main}>
      <Grid item xs className={classes.mainItem}>
        <AccountIcon height="200px" />
        <Typography className={classes.mainTitle}>
          В Личном кабинете клиента.
        </Typography>
        <ButtonComponent disabled fullWidth title="Войти" />
        <Typography align="left" className={classes.mainDescription}>
          Оплачивая задолженность в личном кабинете, вы можете просматривать всю
          историю оплаты, график оплаты, акции, в котороых можете участвовать и
          тем самым снижать стоимость долга.
        </Typography>
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <PayManIcon height="200px" />
        <Typography className={classes.mainTitle}>Оплата по ФИО</Typography>
        <ButtonComponent fullWidth title="Оплатить" onClick={redirectPage} />
        <Typography align="left" className={classes.mainDescription}>
          Оплачивая задолженность по ФИО, вам внимательно нужно ввести свои
          фамилию, имя, отчество, а также дату рождения.
        </Typography>
      </Grid>
      <Grid item xs className={classes.mainItem}>
        <PayRefIcon height="200px" />
        <Typography className={classes.mainTitle}>
          Оплата по номеру договора
        </Typography>
        <ButtonComponent fullWidth title="Оплатить" onClick={redirectPage} />
        <Typography align="left" className={classes.mainDescription}>
          Чтобы оплатить задолженность этим способом, вам необходимо знать номер
          договора. Если номера у вас нет или вы не помните, вы можете
          воспользоваться другим способом оплаты или позвонить по бесплатному
          номеру 8 800 700 20 68 и наши специалисты помогут Вам.
        </Typography>
      </Grid>
    </Grid>
  );
}
