import React, {useState} from "react";
import ButtonComponent from "./button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@mui/material/Checkbox';
import {Link} from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles((theme) => ({
  styleBlockButton: {
    padding: "10px 20px 10px 20px"
    // width: "100%",
    // textAlign: "right"
  },
  inputItem: {
    padding: "10px 20px 10px 20px"
  },
  sendMessage: {
    fontSize: '2rem'
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
  info__block: {
    fontSize: '0.8rem',
    [theme.breakpoints.down("xs")]: {
      margin: "0px 0px 20px 0px"
    }
  }
}));

export default function Feedback() {
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  const [checked, setChecked] = useState(false);

  const [data, setData] = useState({
    fio: "",
    tel: "",
    email: "",
    message: ""
  });


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    handleClickOpen();
    //if (checked) {
    //  fetch("/api/index.php", {
    //    method: "POST",
    //    body: JSON.stringify(data),
    //    headers: {
    //      'Content-Type': 'application/x-www-form-urlencoded'
    //    }
    //  }).then(response => response.text()).then(data => setSent(data));
    //}
  }
  const {fio, tel, email, message} = data;
  if (sent) {
    return (
      <div className={classes.sendMessage}>Спасибо, Ваш запрос отправлен.</div>
    )
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          Отправка данных временно не доступна. Приносим свои извинения!
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Закрыть</Button>
        </DialogActions>
      </Dialog>

      <form autoComplete="off" onSubmit={onSubmit}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            container
            alignContent="space-between"
          // className={classes.padding}
          >
            <Grid item xs={12} className={classes.inputItem}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-outlined">ФИО</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  type="text"
                  name="fio"
                  value={fio}
                  onChange={handleChange}
                  label="ФИО "
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.inputItem}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-outlined">Телефон</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  type="tel"
                  name="tel"
                  value={tel}
                  onChange={handleChange}
                  label="Телефон "
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.inputItem}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="component-outlined">E-mail*</InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  label="E-mail "
                  required
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container className={classes.inputItem}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="component-outlined">Сообщение*</InputLabel>
              <OutlinedInput
                required
                id="filled-textarea"
                type="text"
                name="message"
                label="Сообщение "
                multiline
                rows="11"
                value={message}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            container
            className={classes.styleBlockButton}
            justify="space-between"
          >
            <Grid item className={classes.info__block}>
              <Checkbox
                checked={checked}
                onChange={handleCheckbox}
              />
              Даю согласие на обработку данных*
              <Link to='/official-info'><span className={classes.link}>(Перейти к документу)</span></Link>
            </Grid>
            <ButtonComponent disabled={!checked} type="submit" title="Отправить" size="medium" />
          </Grid>
        </Grid>
        <div></div>
      </form>
    </>
  );
}
