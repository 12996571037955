import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {NavLink, Switch, Route} from "react-router-dom";
import BlockContent from "../../components/blockContent";
import Feedback from "../../components/feedback";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1020px',
    },
    main: {
        padding: '0px 40px',
        textAlign: "left"
    },
    mainTitle: {
        padding: "10px",
        background: "#F3F4F4",
        color: "#484d62",
        margin: "20px 0px 10px 0px",
        textAlign: "center"
    },
    mainItem: {
        margin: "10px"
    },
    mainDescription: {
        margin: "20px auto"
    },
    list: {
        padding: "20px"
    },
    titleBlock: {
        color: "#484D62",
        fontSize: "2rem",
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: "bold",
        padding: "0px 0px 30px 20px"
    },
    title: {
        fontSize: "5rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    content: {
        backgroundColor: "#F3F4F4",
        borderTop: "1px solid #D0D7DD"
    },
    blockImg: {
        border: "1px solid #f3f4f4",
        textAlign: "center",
        borderRadius: "7px",
        background: "#f3f4f4",
        padding: "20px",
        margin: "20px"
    },
    linkButton: {
        width: "100%",
        display: "block",
        textAlign: "center"
    },
    selected: {
        borderBottom: '1px solid black'
    },
    linkItem: {
        padding: '10px',
    },
    main__content_p: {
        margin: '10px 0px'
    }
}));

export default function PersonalInformation() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid className={classes.main}>
                <div className={classes.main__content_p}>«Настоящим, я &#40;далее – Субъект&#41; даю свое согласие Обществу ограниченной ответственности КА «Возврат» &#40;ОГРН 1126658009378, ИНН 6658408512&#41; &#40;далее – Общество&#41;, зарегистрированному по адресу: 620073, Свердловская обл. г. Екатеринбург ул. Крестинского д.46А оф. 708, и привлекаемым Обществом лицам, на обработку моих персональных данных и подтверждаю, что давая такое согласие, я действую своей волей и в своем интересе.</div>

                <div className={classes.main__content_p}>Общество осуществляет обработку персональных данных Субъекта исключительно:</div>

                <li>в целях проверки предоставленных Субъектом данных;</li>
                <li>заключения и/или исполнения договора, стороной которого является Субъект;</li>
                <li>в целях оказания услуг Субъекту;</li>

                <div className={classes.main__content_p}>Согласие распространяется на следующую информацию: фамилия, имя, отчество, дата рождения, паспортные данные, фактический адрес проживания, адрес регистрации; контактный телефон &#40;домашний, мобильный, рабочий&#41;; наименование организации, в которой в момент подписания настоящего согласия трудится Субъект и адрес размещения офиса; сведения о трудовом стаже; и другая информация, относящаяся к моей личности.</div>

                <div className={classes.main__content_p}>Обработка персональных данных осуществляется Обществом с использованием средств автоматизации или без использования таких средств &#40;неавтоматизированная обработка&#41;.</div>

                <div className={classes.main__content_p}>При обработке персональных данных Общество не ограничено в применении способов их обработки. Согласие предоставляется на осуществление любых действий в отношении Персональных данных, которые необходимы для достижения вышеуказанных целей, включая без ограничения: сбор, запись, систематизацию, накопление, хранение, уточнение &#40;обновление, изменение&#41;, использование, распространение &#40;в том числе передача&#41;, обезличивание, блокирование, уничтожение, а также осуществление любых иных действий с персональными данными в соответствии с действующим законодательством.</div>

                <div className={classes.main__content_p}>Общество вправе передавать персональные данные агрегаторам данных, сотовым операторам, финансовым организациям, страховым компаниям, государственным органам и иным лицам, поскольку они оказывают Обществу услуги, имеющие цели, идентичные указанным выше целям передачи персональных данных, или имеют право затребовать предоставления таких персональных данных в соответствии с действующим законодательством РФ.</div >

                <div className={classes.main__content_p}>Настоящим я признаю и подтверждаю, что Общество вправе в необходимом объеме раскрывать для совершения вышеуказанных действий информацию обо мне лично&#40;включая мои персональные данные&#41; таким третьим лицам, их агентам и иным уполномоченным ими лицам, а также предоставлять таким лицам соответствующие документы, содержащие такую информацию.</div >

                <div className={classes.main__content_p}>Также настоящим я признаю и подтверждаю, что настоящее согласие считается данным мною любым третьим лицам, указанным выше, с учетом соответствующих изменений, и любые такие третьи лица имеют право на обработку персональных данных на основании настоящего согласия.</div >

                <div className={classes.main__content_p}>Настоящее согласие дается мною бессрочно, но может быть отозвано посредством направления мною письменного уведомления Обществу не менее чем за 1 &#40;один&#41; месяц до момента отзыва согласия.</div >

                <div className={classes.main__content_p}>Я подтверждаю, что ознакомлен &#40;а&#41; с положениями Федерального закона от 27.07.2006 №152 - ФЗ «О персональных данных» и Федерального закона от 03.07.2016 № 230 - ФЗ «О защите прав и законных интересов физических лиц при осуществлении деятельности по возврату просроченной задолженности». Права и обязанности в области защиты персональных данных мне понятны.»</div >
            </Grid >
        </div >
    );
}
