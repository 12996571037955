import React, {useState} from "react";
import BorderForComponent from "../../components/borderForComponent";
import ButtonComponent from "../../components/button";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Spinner from 'react-spinkit';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "50px 0px 50px 0px",
        boxSizing: "border-box",
    },
    root: {
        boxShadow: '0px 4px 40px 0px #e3e3e3',
        '-webkit-box-shadow': '0px 4px 40px 0px #e3e3e3',
        margin: "40px 0px 40px 0px",
        padding: "50px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px"
        }
    },
    title: {
        fontSize: "3rem",
        paddingBottom: "20px",
        fontFamily: 'Segoe UI',
        color: '#484D62',
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    inputItem: {
        padding: "10px 20px 10px 20px"
    },
    spinner__main: {
        position: 'absolute'
    }
}));

export default function StepPayThird({data, handleChange}) {

    const classes = useStyles();
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(true);

    const hideSpinner = () => {
        setLoading(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
    }
    const [{firstname, lastname, secondname, birthday, account}, {phone, email, amount}] = data;
    const testDataFrame = {
        account: account,
        payee: `${firstname} ${lastname} ${secondname}`,
        amount: amount,
        date: birthday,
        phone: phone,
        flowType: "DEFAULT",
    }
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    container
                    alignContent="space-between"
                // className={classes.padding}
                >
                    <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        //alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        {
                            loading ?
                                (
                                    <div className={classes.spinner__main}>
                                        <Spinner name="three-bounce" fadeIn="quarter" />
                                    </div>

                                )
                                : null
                        }


                        <iframe
                            title="pay"
                            allow="fullscreen"
                            onLoad={hideSpinner}
                            style={{
                                width: '100%',
                                height: '930px',
                            }}
                            frameborder="0"
                            src={`https://koronapay.com/repayment/online/kavozvrat?prefill=${JSON.stringify(testDataFrame)}`}
                        >
                            asd pay
                        </iframe>
                    </div>
                </Grid>
                {/*<Grid
                    item
                    xs={12}
                    container
                    className={classes.styleBlockButton}
                    justify="flex-end"
                >
                    <ButtonComponent type="submit" title="Отправить" size="medium" />
                </Grid>*/}
            </Grid>
        </form>
    );
}
