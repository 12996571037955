import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import PaySberItem from "./paySberItem";
import {dataInstructionSber} from './data';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1020px',
    }
}));

const PaySber = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {
                dataInstructionSber.map((el, id) => {
                    const {text, imgLeft, imgRight} = el;
                    return (
                        <PaySberItem
                            imgLeft={`/images/payment_instruction/${imgLeft}`}
                            imgRight={`/images/payment_instruction/${imgRight}`}
                            text={text}
                            i={id + 1}
                        />
                    )
                })
            }
        </div>
    )
}

export default PaySber;
