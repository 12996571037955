import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1020px',
    },
    main: {
        padding: '0px 40px',
        textAlign: "left"
    },
    mainTitle: {
        padding: "10px",
        background: "#F3F4F4",
        color: "#484d62",
        margin: "20px 0px 10px 0px",
        textAlign: "center"
    },
    mainItem: {
        margin: "10px"
    },
    mainDescription: {
        margin: "20px auto"
    },
    list: {
        padding: "20px"
    },
    titleBlock: {
        color: "#484D62",
        fontSize: "2rem",
        fontFamily: "Segoe UI, sans-serif",
        fontWeight: "bold",
        padding: "0px 0px 30px 20px"
    },
    title: {
        fontSize: "5rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    content: {
        backgroundColor: "#F3F4F4",
        borderTop: "1px solid #D0D7DD"
    },
    blockImg: {
        border: "1px solid #f3f4f4",
        textAlign: "center",
        borderRadius: "7px",
        background: "#f3f4f4",
        padding: "20px",
        margin: "20px"
    },
    linkButton: {
        width: "100%",
        display: "block",
        textAlign: "center"
    },
    selected: {
        borderBottom: '1px solid black'
    },
    linkItem: {
        padding: '10px',
    },
    main__content_p: {
        margin: '10px 0px'
    }
}));

export default function Privacy() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container className={classes.main}>
                <div className={classes.main__content_p}>Политика конфиденциальности
                    Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте kavozvrat.ru, &#40;далее – Сайт&#41;, путем заполнения полей онлайн-оплаты</div>

                <div className={classes.main__content_p}>
                    Пользователь:
                </div>

                <div>
                    <li>подтверждает, что все указанные им данные принадлежат лично ему; </li>

                    <li>подтверждает и признает, что им внимательно в полном объеме прочитано Соглашение и условия обработки его персональных данных, указываемых им в полях онлайн-оплаты, текст Соглашения и условия обработки персональных данных ему понятны; </li>

                    <li>дает согласие на обработку Сайтом предоставляемых в составе информации персональных данных; </li>

                    <li>dыражает согласие с условиями обработки персональных данных без оговорок и ограничений; </li>
                </div>

                <div className={classes.main__content_p}>
                    Обработка персональных данных Субъекта осуществляется исключительно:
                </div>

                <div>
                    <li>в целях проверки предоставленных Субъектом данных; </li>

                    <li>заключения и/или исполнения договора, стороной которого является Субъект; </li>

                    <li>в целях оказания услуг Субъекту; </li>
                </div>

                <div className={classes.main__content_p}>
                    Согласие Пользователя на обработку персональных данных является конкретным, информированным и сознательным.
                </div>

                <div className={classes.main__content_p}>
                    Настоящее согласие Пользователя признается исполненным в простой письменной форме, и дает право на обработку следующих персональных данных Пользователя: фамилия, имя, отчество; дата рождения; паспортные данные; номер телефона; адрес электронной почты &#40; E-mail&#41;; фактический адрес проживания; адрес регистрации; контактный телефон &#40; домашний, мобильный, рабочий&#41;; и другая информация, относящаяся к Пользователю.
                </div>

                <div className={classes.main__content_p}>
                    Пользователь, предоставляет  kavozvrat.ru право осуществлять следующие действия &#40; операции&#41; с персональными данными &#40; с использованием средств автоматизации или без использования таких средств &#40; неавтоматизированная обработка&#41;: сбор; запись; систематизация; накопление; хранение в течение установленных нормативными документами сроков хранения отчетности, но не менее трех лет с даты прекращения пользования услугами Пользователем; уточнение &#40; обновление, изменение&#41;; использование; блокирование; уничтожение; обезличивание; передача третьим лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа.
                </div>

                <div className={classes.main__content_p}>
                    Сайт вправе передавать персональные данные агрегаторам данных, сотовым операторам, финансовым организациям, страховым компаниям, государственным органам и иным лицам, поскольку они оказывают Обществу услуги, имеющие цели, идентичные указанным выше целям передачи персональных данных.
                </div>


                <div className={classes.main__content_p}>
                    Сайт имеет право вносить изменения в настоящее Соглашение.При внесении изменений в актуальной редакции указывается дата последнего обновления.Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.
                </div>

                <div className={classes.main__content_p}>
                    Действующая редакция всегда находится на странице по адресу: <Link to='/official-info/privacy'>https://kavozvrat.ru/official-info/privacy</Link>
                </div>

                <div className={classes.main__content_p}>
                    К настоящему Соглашению и отношениям между Пользователем и Сайтом, возникающим в связи с применением Соглашения, применяется право Российской Федерации.
                </div>

            </Grid>
        </div>
    );
}
