import React from "react";
import ClientAllServices from "./clientServicesAll";
import BlockContent from "./blockContent";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  content: {
    // backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  content__block: {
    paddingBottom: "40px",
  },
}));

export default function Services() {
  const classes = useStyles();
  return (
    <BlockContent title="Услуги" className={classes.content}>
      <Grid container wrap="nowrap" className={classes.content__block}>
        <ClientAllServices />
      </Grid>
    </BlockContent>
  );
}
