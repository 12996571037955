import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    margin: "0 auto",
    maxWidth: "1100px",
    boxSizing: "border-box",
    padding: "0px 40px 0px 40px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 15px 0px 15px"
    }
  }
}));

export default function BorderForComponent({ children, className }) {
  const classes = useStyles();
  return <div className={clsx(classes.main, className)}>{children}</div>;
}
