import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        padding: '40px',
        //maxWidth: '1020px',
        boxSizing: 'border-box',
        margin: '40px auto',
        boxShadow: '0px 4px 40px 0px #e3e3e3',
        '-webkit-box-shadow': '0px 4px 40px 0px #e3e3e3',
        gridTemplateAreas:
            `
            ". . ."
            "text imgLeft imgRight"
        `,
        [theme.breakpoints.down("xs")]: {
            padding: '0px 40px 40px 40px',
            gridTemplateColumns: 'auto auto auto',
            gridTemplateAreas:
                `
            "text text text"
            "imgLeft . imgRight"
        `,
        }
    },
    instructionImg_main: {

    },
    instructionImgLeft: {
        gridArea: 'imgLeft',
    },
    instructionImgRight: {
        gridArea: 'imgRight',
    },
    instructionImg: {
        width: '70%',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
        }
    },
    instructionText: {
        gridArea: 'text',
        alignSelf: 'center',
        padding: '20px',
        margin: '20px',
        borderBottom: '1px solid black',
        [theme.breakpoints.down("xs")]: {
            margin: '20px 0px 20px 0px',
            padding: '0px 0px 20px 0px',
        }
    },
    instructionText_step: {
        fontSize: '2rem',
        fontFamily: 'Segoe UI, sans-serif',
        fontWeight: 'bold',
        margin: '0px 0px 20px 0px',
    },
    instructionText_description: {
        fontFamily: 'Segoe UI, sans-serif',
        fontSize: '1rem',
    }
}));

const PaySberItem = ({text, imgLeft, imgRight, i}) => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.instructionText}>
                <div className={classes.instructionText_step}>Шаг {i}</div>
                <div className={classes.instructionText_description}>{text}</div>
            </div>
            <div className={clsx(classes.instructionImg_main, classes.instructionImgLeft)}><img className={classes.instructionImg} src={imgLeft} alt="Инструкция по оплате Сбер" /></div>
            <div className={clsx(classes.instructionImg_main, classes.instructionImgRight)}><img className={classes.instructionImg} src={imgRight} alt="Инструкция по оплате Сбер" /></div>
        </div>
    )
}

export default PaySberItem;
