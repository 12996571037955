import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ModalDataInput from "../../components/cardImgText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../../components/button";
import ReferenceContentItem from "../servicesClient/components/referenceItem";
import {ReactComponent as PayManIcon} from "../../static/svg/payMan.svg";
import {ReactComponent as PayRefIcon} from "../../static/svg/payRef.svg";
import {ReactComponent as AccountIcon} from "../../static/svg/account2.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    // textAlign: "left"
  },
  mainTitle: {
    padding: "10px",
    background: "#F3F4F4",
    color: "#484d62",
    margin: "20px 0px 10px 0px",
    textAlign: "center"
  },
  mainItem: {
    margin: "10px"
  },
  mainDescription: {
    margin: "20px auto"
  },
  list: {
    padding: "20px"
  },
  titleBlock: {
    color: "#484D62",
    fontSize: "2rem",
    fontFamily: "Segoe UI, sans-serif",
    fontWeight: "bold",
    padding: "0px 0px 30px 20px"
  }
}));
export default function ReferenceContent({title, url, img2}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item container className={classes.main}>
        <ReferenceContentItem
          title="В Личном кабинете клиента"
          icon={<AccountIcon height="200px" />}
          decription="Оплачивая задолженность в личном кабинете, вы можете просматривать
          всю историю оплаты, график оплаты, акции, в котороых можете
          участвовать и тем самым снижать стоимость долга."
          buttonTitle="Войти"
          disableButton={true}
        />
        <ReferenceContentItem
          title="Справка об отсутствии задолженности"
          icon={<PayManIcon height="200px" />}
          decription="Справка выдается после полного погашения задолженности. Документ
          можно получить бесплатно в режиме онлайн, заполнив специальную
          форму."
          buttonTitle="Запросить справку"
        // onClick={handleClickOpen}
        />
        <ReferenceContentItem
          title="Заказать график погашения"
          icon={<PayRefIcon height="200px" />}
          decription=" Узнать график платежей, а так же получить справку о остатке
          задолженности онлайн, заполнив специальную форму."
          buttonTitle="Заказать график"
        // onClick={handleClickOpen}
        />
      </Grid>
    </>
  );
}
