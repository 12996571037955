import React from "react";
import BorderForComponent from "./borderForComponent";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

const useStyles = makeStyles((theme) => ({
  menuStyle: {
    display: "flex",
    width: "100%",
    outline: "none",
    flexWrap: "wrap"
  },
  blockNavigation: {
    background: "#F3F4F4",
    color: "#484D62",
    marginLeft: "-16px",
    // boxShadow:
    //   "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  border: {
    border: "1px solid #484D62",
    borderRadius: "5px"
  }
}));

export default function Menu() {
  const classes = useStyles();

  return (
    <div className={classes.blockNavigation}>
      <BorderForComponent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <MenuList autoFocus className={classes.menuStyle}>
              <MenuItem component="div">
                <Link to="/stock">Акции</Link>
              </MenuItem>
              <MenuItem component="div">
                <Link to="/client">Клиенту</Link>
              </MenuItem>
              {/* <MenuItem component="div">
                <Link to="/news">Бизнесу</Link>
              </MenuItem> */}
              <MenuItem component="div">
                <Link to="/about">О компании</Link>
              </MenuItem>
              <MenuItem component="div">
                <Link to="/contact">Контакты</Link>
              </MenuItem>
              {/*<MenuItem className={classes.border}>
                <Link to="/investments">Инвестиции</Link>
              </MenuItem>*/}
            </MenuList>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              xs
              container
              wrap="nowrap"
              justify="flex-end"
              alignItems="flex-end"
            >
              <LocationOnOutlinedIcon />
              Екатеринбург
            </Grid>
          </Hidden>
        </Grid>
      </BorderForComponent>
    </div>
  );
}
