import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink, Switch, Route} from "react-router-dom";
import BorderForComponent from "../../components/borderForComponent";
import StepsPay from "./stepsPay";
import PaySber from "./paySber";
import PayBankDetails from "./payBankDetails";


const useStyles = makeStyles((theme) => ({
  main: {
    padding: "50px 0px 20px 0px",
    boxSizing: "border-box",
  },
  root: {
    boxShadow: "0px 4px 40px 0px #e3e3e3",
    '-webkit-box-shadow': "0px 4px 40px 0px #e3e3e3",
    margin: "40px 0px 40px 0px",
    padding: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px"
    }
  },
  title: {
    fontSize: "3rem",
    paddingBottom: "20px",
    fontFamily: 'Segoe UI',
    color: '#484D62',
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem"
    }
  },
  inputItem: {
    padding: "10px 20px 10px 20px"
  },
  selected: {
    background: '#F2C807',
    borderBottom: 'none!important'
  },
  payLinkItem: {
    padding: '10px',
  },
  linkItem__content: {
    padding: '5px',
    borderBottom: '1px dashed black',
  },
  spinner__main: {
    position: 'absolute'
  }
}));

export default function Pay() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main} >
        <h2 className={classes.title}>Оплатить задолженность онлайн</h2>
      </div>
      <Grid container justify="center">
        <Grid item className={classes.payLinkItem}><NavLink to='/pay/sber' activeClassName={classes.selected} className={classes.linkItem__content}>оплатить в Сбербанк Онлайн</NavLink></Grid>
        <Grid item className={classes.payLinkItem}><NavLink exact to='/pay' activeClassName={classes.selected} className={classes.linkItem__content}>оплатить на сайте</NavLink></Grid>
        <Grid item className={classes.payLinkItem}><NavLink to='/pay/bank-details' activeClassName={classes.selected} className={classes.linkItem__content}>оплатить по "Реквизитам"</NavLink></Grid>
      </Grid>
      <Switch>
        <Route exact path="/pay">
          <BorderForComponent>
            <Grid container className={classes.root}>
              {/*<div style={{textAlign: 'center', width: '100%'}}>Оплата временно недоступна! Обратитесь в контактный центр: 8-800-700-20-68</div>*/}
              <StepsPay />
            </Grid>
          </BorderForComponent>
        </Route>
        <Route path="/pay/sber">
          <PaySber />
        </Route>
        <Route path="/pay/bank-details">
          <PayBankDetails />
        </Route>
      </Switch>
    </>
  );
}
