import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from '../../components/button';
import StepPayFirst from './stepPayFirst';
import StepPaySecond from './stepPaySecond';
import StepPayThird from './stepPayThird';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    colorIcon: {
        color: '#484D62!important',
    },
    colorCompleted: {
        color: '#F2C807!important'
    },
    colorTextStep: {
        color: '#484D62!important',
    },
    buttonBox: {
        margin: "30px 0px 0px 0px"
    }
}));

function getSteps() {
    return ['Данные о заёмщике', 'Данные об оплате', 'Оплата'];
}

const titleButton = ['Далее', 'К оплате', 'ХЗ']

export default function StepsPay() {
    const classes = useStyles();
    const [data, setData] = useState([
        {
            firstname: "",
            lastname: "",
            secondname: "",
            birthday: "",
            account: "",
            agreement: false
        },
        {
            phone: "",
            email: "no",
            amount: "",
            metod: "ex"
        }
    ]);
    const [activeStep, setActiveStep] = useState(0);
    const [disableNext, setDisableNext] = useState(true)
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleButtonNext = useCallback((state) => {
        const a = state[activeStep];
        const notEmpty = [];
        for (var key in a) {
            debugger
            if (a[key].length === 0 || a[key] === false) {
                notEmpty.push(false);
                setDisableNext(true);
                return
            } else {
                notEmpty.push(true)
            }
        }
        setDisableNext(false);
        //const result = notEmpty.every(el => el === true);
        //if (result) {
        //    setDisableNext(false)
        //} else {
        //    setDisableNext(true)
        //}
    }, [activeStep])

    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.type === 'tel') {
            console.log('this is tel')
        }
        if (e.target.type === 'checkbox') {
            console.log(e.target.value)
            console.log(e.target.checked)
            value = e.target.checked;
        }
        const result = {...data[activeStep], [e.target.name]: value};
        const newState = data.map((el, id) => id === activeStep ? result : {...el});
        handleButtonNext(newState);

        setData(newState);
    };

    const selectTitleButton = () => {
        if (activeStep !== 2) {
            return (
                <ButtonComponent disabled={disableNext} variant="contained" color="primary" onClick={handleNext} size="medium" title={titleButton[activeStep]} />
            )
        } else {
            return null
        }
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <StepPayFirst data={data[0]} handleChange={handleChange} />;
            case 1:
                return <StepPaySecond data={data[1]} handleChange={handleChange} />;
            case 2:
                return <StepPayThird data={data} handleChange={handleChange} />;
            default:
                return 'Unknown stepIndex';
        }
    }

    useEffect(() => {
        handleButtonNext(data)
    }, [activeStep, data, handleButtonNext])
    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconProps={{
                            classes: {
                                active: classes.colorIcon,
                                completed: classes.colorCompleted,
                            }
                        }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {
                activeStep === steps.length ? (
                    <>
                        <Typography className={classes.instructions}>Оплата поступит в течение суток, спасибо!</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </>
                ) : (
                    <>
                        {getStepContent(activeStep)}
                        <div className={classes.buttonBox}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Назад
                            </Button>
                            {selectTitleButton()}
                        </div>
                    </>
                )
            }
        </div >
    );
}
