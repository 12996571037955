import React from "react";
import ContactData from "./contactData";
import BorderForComponent from "./borderForComponent";
import Menu from "./menu";
import ButtonComponent from "./button";
import clsx from "clsx";
import SvgIcon from "@material-ui/core/SvgIcon";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {ReactComponent as LogoIcon} from "../static/logo-light.svg";
import {ReactComponent as PayIcon} from "../static/pay.svg";
import {ReactComponent as AccountIcon} from "../static/account.svg";

const useStyles = makeStyles((theme) => ({
  containerPadding: {
    padding: "10px 0px 10px 0px"
  },
  blockInformation: {
    color: "#F3F4F4",
    background: "#484D62",
    borderBottom: "1px solid #1c345969"
  },
  gridItemContact: {
    flexWrap: "nowrap",
    // padding: "10px",
    alignItems: "center"
  },
  svgSize: {
    fontSize: "9.5rem",
    height: "0.5em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10rem"
    }
  },
  blockMenuMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "right"
    }
  },
  buttonMarg: {
    marginRight: "20px"
  },
  buttonText: {
    maxWidth: "150px",
    lineHeight: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  buttonAccount: {
    backgroundColor: "#d5d5d5",
    "&:hover": {
      backgroundColor: "#c6c3ba"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  classNameTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

export default function AppBarContainer() {
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <div className={classes.blockInformation}>
        <BorderForComponent>
          <Grid
            item
            xs
            container
            justify="space-between"
            alignItems="center"
            className={classes.containerPadding}
          >
            <Grid item xs container alignItems="center" wrap="nowrap">
              <Link to="/">
                <SvgIcon
                  component={LogoIcon}
                  viewBox="0 0 200 100"
                  width="100%"
                  height="100%"
                  className={classes.svgSize}
                />
              </Link>
            </Grid>
            <ContactData />
            <Grid
              item
              className={clsx(classes.gridItemContact, classes.buttonMarg)}
            >
              <Link to="/pay/sber">
                <ButtonComponent
                  className={classes.buttonText}
                  classNameTitle={classes.classNameTitle}
                  title="Узнать долг и оплатить"
                  startIcon={<PayIcon width="2rem" height="2rem" />}
                />
              </Link>
            </Grid>
            <Grid item className={classes.gridItemContact}>
              <Link to="/page-construction">
                <ButtonComponent
                  className={classes.buttonAccount}
                  classNameTitle={classes.classNameTitle}
                  title="Личный кабинет"
                  startIcon={<AccountIcon width="2rem" height="2rem" />}
                />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs className={classes.blockMenuMobile}>
            {/* <MenuIcon /> */}
            <a href="tel:8-800-700-20-68" style={{color: "white"}}>
              8-800-700-20-68
            </a>
          </Grid>
        </BorderForComponent>
      </div>
      <Menu />
    </AppBar>
  );
}
