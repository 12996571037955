import React, {useState} from "react";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import ButtonComponent from "./button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@mui/material/Checkbox';
import {Link} from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const useStyles = makeStyles((theme) => ({
  styleBlockButton: {
    padding: "10px 20px 10px 20px"
    // width: "100%",
    // textAlign: "right"
  },
  inputItem: {
    padding: "10px 20px 10px 20px"
  },
  sendMessage: {
    fontSize: '2rem'
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
  info__block: {
    fontFamily: 'Open Sans, arial',
    fontSize: '0.7rem'
  },
  error: {
    padding: '0px 20px',
    color: 'red'
  }
}));
const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function ModalDataInput({
  open,
  handleClose,
  handleClickOpen,
  title
}) {
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    secondName: "",
    patronymic: "",
    dateOfBirth: "",
    tel: "",
    email: "",
    numbeContract: ""
  });

  const [checked, setChecked] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
  }


  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setOpenError(true);
    //if (checked) {
    //  fetch("/api/feedback.php", {
    //    method: "POST",
    //    body: JSON.stringify(data),
    //    headers: {
    //      'Content-Type': 'application/x-www-form-urlencoded'
    //    }
    //  }).then(response => response.text()).then(data => setSent(data));
    //}
  }
  const {
    firstName,
    secondName,
    patronymic,
    dateOfBirth,
    tel,
    email,
    numbeContract
  } = data;
  if (sent) {
    return (
      <div className={classes.sendMessage}>Спасибо, Ваш запрос отправлен.</div>
    )
  }
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <form autoComplete="off" onSubmit={onSubmit}>
          <DialogContent dividers>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                container
                alignContent="space-between"
              // className={classes.padding}
              >
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="component-outlined">Имя</InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      label="Имя "
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="component-outlined">
                      Фамилия
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type="text"
                      name="secondName"
                      value={secondName}
                      onChange={handleChange}
                      label="Фамилия "
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="component-outlined">
                      Отчество
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type="text"
                      name="patronymic"
                      value={patronymic}
                      onChange={handleChange}
                      label="Отчество "
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel shrink htmlFor="component-outlined">
                      Дата рождения
                    </InputLabel>
                    <OutlinedInput
                      notched
                      id="component-outlined"
                      type="date"
                      name="dateOfBirth"
                      value={dateOfBirth}
                      onChange={handleChange}
                      label="Дата рождения "
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="component-outlined">
                      Телефон
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type="tel"
                      name="tel"
                      value={tel}
                      onChange={handleChange}
                      label="Телефон "
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.inputItem}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="component-outlined">E-mail*</InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      label="E-mail "
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} container className={classes.inputItem}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="component-outlined">
                    Номер кредитного договора
                  </InputLabel>
                  <OutlinedInput
                    id="component-outlined"
                    type="text"
                    name="numbeContract"
                    value={numbeContract}
                    onChange={handleChange}
                    label="Номер кредитного договора "
                  />
                </FormControl>
              </Grid>
              {
                openError &&
                <div className={classes.error}>Отправка данных временно не доступна. Приносим свои извинения!</div>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              item
              xs={12}
              container
              className={classes.styleBlockButton}
              justify="space-between"
            >
              <Grid item className={classes.info__block}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckbox}
                />  Даю согласие на обработку данных
                <Link to='/official-info'><span className={classes.link}>(Перейти к документу)</span></Link>
              </Grid>
              <ButtonComponent disabled={!checked} type="submit" title="Отправить" size="medium" />
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
