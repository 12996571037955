import React from "react";
import BlockContent from "./blockContent";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#F3F4F4",
    borderTop: "1px solid #D0D7DD"
  },
  contentItem: {
    padding: "20px",

    "&:hover": {
      // margin: "1px",
    }
  },
  contentItem_p: {
    width: "200px",
    height: "100%",
    padding: "15px",
    boxSizing: "border-box",
    textAlign: "left",
    backgroundColor: "#F3F4F4"
  },
  contentItem_p_text: {
    padding: "5px 0px 5px 0px"
  },
  contentItem_p_title: {
    lineHeight: "1.2",
    padding: "5px 0px 5px 0px",
    borderBottom: "1px solid #D0D7DD",
    borderTop: "1px solid #D0D7DD"
  }
}));

export default function News() {
  const classes = useStyles();
  return (
    <BlockContent title="Новости" className={classes.content}>
      <Grid item xs className={classes.contentItem}>
        <Paper elevation={2} className={classes.contentItem_p}>
          <Typography variant="caption">27.02.2021 18:10:08</Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.contentItem_p_title}
          >
            Эксперты: в России доля просроченных микрозаймов выросла до 37%
          </Typography>
          <Typography variant="body2" className={classes.contentItem_p_text}>
            По данным бюро кредитных историй "Эквифакс", объем просроченных
            долгов МФО в январе составил 66,4 млрд рублей...
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs className={classes.contentItem}>
        <Paper elevation={2} className={classes.contentItem_p}>
          <Typography variant="caption">27.02.2021 18:10:08</Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.contentItem_p_title}
          >
            У коллекторов наступил низкий сезон
          </Typography>
          <Typography variant="body2" className={classes.contentItem_p_text}>
            Отложенный спрос на продажу долгов продолжает откладываться...
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs className={classes.contentItem}>
        <Paper elevation={2} className={classes.contentItem_p}>
          <Typography variant="caption">27.02.2021 18:10:08</Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.contentItem_p_title}
          >
            Груз "старых" кредитов: почему россияне стали чаще
            банкротиться//Газета.ру
          </Typography>
          <Typography variant="body2" className={classes.contentItem_p_text}>
            Банкротства физических лиц в России значительно выросли в январе...
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs className={classes.contentItem}>
        <Paper elevation={2} className={classes.contentItem_p}>
          <Typography variant="caption">27.02.2021 18:10:08</Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.contentItem_p_title}
          >
            Вирус прошел без претензий// Коммерсант
          </Typography>
          <Typography variant="body2" className={classes.contentItem_p_text}>
            Рост жалоб на взыскателей замедлился...
          </Typography>
        </Paper>
      </Grid>
    </BlockContent>
  );
}
