import React, {useState} from "react";
import BorderForComponent from "../../components/borderForComponent";
import ButtonComponent from "../../components/button";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Checkbox from '@mui/material/Checkbox';
import {Link} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    main: {
        padding: "50px 0px 50px 0px",
        boxSizing: "border-box",
    },
    root: {
        boxShadow: '0px 4px 40px 0px #e3e3e3',
        '-webkit-box-shadow': '0px 4px 40px 0px #e3e3e3',
        margin: "40px 0px 40px 0px",
        padding: "50px",
        [theme.breakpoints.down("xs")]: {
            padding: "20px"
        }
    },
    title: {
        fontSize: "3rem",
        paddingBottom: "20px",
        fontFamily: 'Segoe UI',
        color: '#484D62',
        [theme.breakpoints.down("xs")]: {
            fontSize: "3rem"
        }
    },
    inputItem: {
        padding: "10px 20px 10px 20px"
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
    },
    info__block: {
        fontSize: '0.8rem',
        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px 20px 0px"
        }
    }
}));

export default function StepPayFirst({data, handleChange}) {

    const classes = useStyles();
    const [sent, setSent] = useState(false);
    const [checked, setChecked] = useState(false);
    //const [data, setData] = useState({
    //    firstname: "",
    //    lastname: "",
    //    secondname: "",
    //    birthday: ""
    //});

    //const handleChange = (e) => {
    //    setData({...data, [e.target.name]: e.target.value});
    //};

    const onSubmit = (e) => {
        e.preventDefault();
    }
    const handleCheckbox = (e) => {
        setChecked(e.target.checked);
    }


    const {firstname, lastname, secondname, birthday, account} = data;
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    container
                    alignContent="space-between"
                // className={classes.padding}
                >
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="component-outlined">Введите Имя заёмщика *</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                name="firstname"
                                value={firstname}
                                onChange={handleChange}
                                label="Введите Имя заёмщика *"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="component-outlined">Введите Фамилию  заёмщика *</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                name="lastname"
                                value={lastname}
                                onChange={handleChange}
                                label="Введите Фамилию  заёмщика *"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="component-outlined">Введите Отчество заёмщика *</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                name="secondname"
                                value={secondname}
                                onChange={handleChange}
                                label="Введите Отчество заёмщика *"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel shrink htmlFor="component-outlined">Выберите Дату рождения заемщика *</InputLabel>
                            <OutlinedInput
                                notched
                                id="component-outlined"
                                type="date"
                                name="birthday"
                                value={birthday}
                                onChange={handleChange}
                                label="Выберите Дату рождения заемщика *"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} className={classes.inputItem}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="component-outlined">Номер телефона заёмщика или номер Кредитного Договора *</InputLabel>
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                name="account"
                                value={account}
                                onChange={handleChange}
                                label="Номер телефона заёмщика или номер Кредитного Договора *"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.info__block}>
                        <Checkbox
                            checked={data.agreement}
                            name="agreement"
                            onChange={handleChange}
                        />
                        Даю согласие на обработку данных*
                        <Link to='/official-info'><span className={classes.link}>(Перейти к документу)</span></Link>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
