import React, {useState} from "react";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import {Link} from "react-router-dom";
import ButtonComponent from "./button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});
const useStyles = makeStyles((theme) => ({
    styleBlockButton: {
        padding: "10px 20px 10px 20px"
        // width: "100%",
        // textAlign: "right"
    },
    inputItem: {
        padding: "10px 20px 10px 20px"
    },
    sendMessage: {
        margin: "20px",
        fontSize: '2rem'
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
    },
    info__block: {
        fontSize: '0.8rem',
        fontFamily: 'Open sans, arial',
        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px 20px 0px"
        }
    }
}));
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

export default function ModalInvestInput({
    open,
    handleClose,
    dataOptions,
    title
}) {
    const classes = useStyles();
    const [sent, setSent] = useState(false);
    const [data, setData] = useState({
        firstName: "",
        tel: "",
        email: "",
        options: {}
    });
    const [checked, setChecked] = useState(false);
    const handleCheckbox = (e) => {
        setChecked(e.target.checked);
    }
    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };


    const onSubmit = (e) => {
        e.preventDefault();
        //if (checked) {
        //    const result = {...data, options: dataOptions}
        //    fetch("/api/feedbackInvest.php", {
        //        method: "POST",
        //        body: JSON.stringify(result),
        //        headers: {
        //            'Content-Type': 'application/x-www-form-urlencoded'
        //        }
        //    }).then(response => response.text()).then(data => setSent(data));
        //}
    }

    const {
        firstName,
        tel,
        email
    } = data;
    if (sent) {
        return (
            <div className={classes.sendMessage}>Спасибо, Ваш запрос отправлен.</div>
        )
    }
    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle>
                <form autoComplete="off" onSubmit={onSubmit}>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                container
                                alignContent="space-between"
                            // className={classes.padding}
                            >
                                Отправка данных временно не доступна. Приносим свои извинения!
                                {/*<Grid item xs={12} sm className={classes.inputItem}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="component-outlined">Имя</InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            type="text"
                                            name="firstName"
                                            value={firstName}
                                            onChange={handleChange}
                                            label="Имя "
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm className={classes.inputItem}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="component-outlined">
                                            Телефон
                                        </InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            type="tel"
                                            name="tel"
                                            value={tel}
                                            onChange={handleChange}
                                            label="Телефон "
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm className={classes.inputItem}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="component-outlined">E-mail</InputLabel>
                                        <OutlinedInput
                                            id="component-outlined"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            label="E-mail "
                                            required
                                        />
                                    </FormControl>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            item
                            xs={12}
                            container
                            className={classes.styleBlockButton}
                            justify="space-between"
                        >
                            {/*<Grid item className={classes.info__block}>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckbox}
                                /> Согласен с обработкой данных КА Возрат* <Link to='/official-info'><span className={classes.link}>(Перейти к документу)</span></Link>
                            </Grid>*/}
                            <ButtonComponent disabled={!checked} type="submit" title="Отправить" size="medium" />
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    );
}
