import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F2C807",
    fontSize: "12px",
    textTransform: "none",
    color: "#484D62",
    textAlign: "left",
    fontWeight: "normal",
    padding: "6px 20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "10px 20px",
      fontSize: "1rem",
    },
    "&:hover": {
      backgroundColor: "#f2d658"
    }
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    textAlign: "center"
  },
}));

export default function ButtonComponent({
  title,
  className,
  classNameTitle,
  ...other
}) {
  const classes = useStyles();
  return (
    <ColorButton className={className} {...other}>
      <span className={clsx(classNameTitle, classes.button)}>{title}</span>
    </ColorButton>
  );
}
